.contacts {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  background-image: url("../../../shared/assets/images/contacts/BgImg.webp");
  background-size: cover;
  background-repeat: no-repeat;

  &__container  {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;

    .left_block {
      max-width: 415px;
      max-height: 617px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      color: var(--white);
      text-transform: uppercase;
      text-align: center;

      h2 {
        font-family: Inter;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: center;
      }
      h3 {
        color: #1C3F1E;
        background: #51AF3D;
        padding: 5px 15px 5px 15px;
        border-radius: 6px;

        font-family: Roboto;
        font-size: 64px;
        font-weight: 700;
        line-height: 83px;
        letter-spacing: -0.01em;
        text-align: center;
      }
      p {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      img {
        max-width: 376px;
        height: auto;
        margin-left: -20px;
      }
    }

    .right_block {
      max-width: 441px;

      max-height: calc(100vh - 120px);
      overflow-y: auto;
      padding: 30px;

      background-color: var(--white);
      border: 1px solid var(--neutral)
    }
  }
}

@media (max-width: 1100px) {
  .contacts {
    &__container {
      gap: 30px;

      .left_block {
        max-width: 100%;
        flex-wrap: nowrap;

        h2 {
          font-size: 3vw;
          line-height: 4vw;
          text-wrap: none;
        }
        h3 {
          font-size: 6vw;
          line-height: 8vw;
        }
        p {
          font-size: 2vw;
          line-height: 2vw;
        }

        img {
          max-width: 100%;
        }
      }

      .right_block {
        padding: 30px;
        background-color: var(--white);
        max-width: 441px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .contacts {
    align-items: start;

    &__container {
      max-height: 100%;
      flex-wrap: wrap;
      gap: 30px;

      .left_block {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: var(--white);
        text-transform: uppercase;
        text-align: center;

        h2 {
          font-family: Inter;
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: -0.02em;
          text-align: center;
        }
        h3 {
          color: #1C3F1E;
          background: #51AF3D;
          padding: 5px 15px 5px 15px;
          border-radius: 6px;

          font-family: Roboto;
          font-size: 64px;
          font-weight: 700;
          line-height: 83px;
          letter-spacing: -0.01em;
          text-align: center;
        }
        p {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: center;
        }

        img {
          max-width: 376px;
          height: auto;
          margin-left: -20px;
        }
      }

      .right_block {
        padding: 30px;
        background-color: var(--white);
        max-width: 441px;
        overflow-y: initial;

        form {
          margin-top: 0;

          h3 {
            text-align: center;
            margin-bottom: 30px;

            font-family: Roboto;
            font-size: 23px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
          }
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .contacts {
    padding: 0;

    &__container {
      width: 100%;
      max-width: 100%;

      .left_block {
        max-width: 100%;
        max-height: 100%;
        padding: 0 15px;
      }

      .right_block {
        width: 100%;
        max-width: 100%;
        border: none;
      }
    }
  }
}
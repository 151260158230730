.page {
  width: 100%;
  height: 100vh;
  background: #E7E7E7;

  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .gift_data {
      width: calc(100% - 30px);
      min-height: 268px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background: linear-gradient(180deg, #51AF3D 0%, #20780E 100%);

      h3 {
        color: var(--white);

        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
      }
      h2 {
        margin: 15px 0;
        color: var(--white);

        font-family: Roboto;
        font-size: 45px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: -0.01em;
        text-align: left;
      }
      p {
        color: var(--white);

        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.01em;
        text-align: center;
      }
    }

    .cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      gap: 15px;

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 150px;
        max-width: 100%;
        height: 55px;
        padding: 15px;
        border-radius: 6px;
        background: var(--white);

        p {
          padding-top: 5px;
          color: #000000;

          font-family: Roboto;
          font-size: 15px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.01em;
          text-align: left;

          span {
            color: #51AF3D;

            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
      &__bottom_block {
        display: flex;
        flex-direction: row;
        gap: 15px;

        .cards {
          &__card {
            p {
              color: #000000;

              font-family: Roboto;
              font-size: 15px;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: -0.01em;
              text-align: left;

              span {
                color: #51AF3D;

                font-family: IBM Plex Sans;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
              }
            }

            .card_desc {
              color: #000000;

              font-family: Roboto;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: -0.01em;
              text-align: left;
            }
          }
        }
      }
    }

    .button {
      width: calc(100% - 50px);
      align-self: center;
      margin-top: auto;
      padding-top: 37px;
      padding-bottom: 60px;
      margin-bottom: 60px;

      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .page {
    .container {
      .gift_data {
        padding: 15px;
      }

      .cards {
        margin-top: 20px;
        gap: 10px;

        &__card {
          padding: 5px;

          p {
            padding-top: 3px;
          }
        }
        &__bottom_block {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }

      .button {
        width: calc(100% - 40px);
      }
    }
  }
}